<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 24 24"
    xml:space="preserve"
    width="24"
    height="24"
    fill="currentColor"
    aria-hidden="true"
    preserveAspectRatio="xMidYMid meet"
    role="presentation"
    aria-labelledby="Facebook"
  >
    <path
      d="M23.9,1.1c-0.1-0.6-0.5-1-1.1-1.1c-0.1,0-0.1,0-0.1,0H1.3L1.1,0C0.5,0.1,0.1,0.5,0,1.1l0,0.1v21.5c0,0,0,0.1,0,0.1 c0.1,0.6,0.5,1,1.1,1.1c0,0,0.1,0,0.1,0h11.5c0,0,0-0.1,0-0.2v-9.1h-3c-0.1,0-0.1,0-0.1-0.1v-3.4c0-0.1,0-0.1,0.1-0.1 c1,0,1.9,0,2.9,0h0.1v-0.1c0-0.9,0-1.9,0-2.8c0-0.8,0.2-1.5,0.5-2.2c0.4-0.9,1.1-1.6,2.1-2c0.6-0.3,1.3-0.4,2-0.4 c0.8,0,1.6,0,2.4,0.1c0.1,0,0.3,0,0.4,0c0.1,0,0.1,0,0.1,0.1v3c0,0.1,0,0.1-0.1,0.1c-0.6,0-1.2,0-1.8,0c-0.3,0-0.5,0-0.8,0.1 c-0.5,0.1-0.8,0.5-0.9,1c0,0.2-0.1,0.4-0.1,0.6c0,0.8,0,1.5,0,2.3c0,0,0,0,0,0.1H20c0.1,0,0.1,0,0.1,0.1c-0.1,0.6-0.2,1.2-0.2,1.8 l-0.2,1.5c0,0.2,0,0.2-0.2,0.2h-2.9V24h6.2c0,0,0.1,0,0.1,0c0.6-0.1,1-0.5,1.1-1.1c0,0,0-0.1,0-0.1V1.3C24,1.2,23.9,1.2,23.9,1.1z"
    />
  </svg>
</template>
